import * as React from 'react'
import cn from 'classnames'

export type CircleProgressBarProps = {
  progressPercent: number
  size?: 'sm' | 'md'
  enableColorScale?: boolean
}

export const CircleProgressBar: React.VFC<CircleProgressBarProps> = (props) => {
  const { progressPercent, size = 'sm', enableColorScale = true } = props

  const sizeClass = {
    sm: 'w-4 h-4',
    md: 'w-6 h-6',
  }[size]

  const radius = 30
  const circumference = 2 * Math.PI * radius

  const strokeDashoffset =
    circumference - (progressPercent / 100) * circumference

  let circleColour = 'text-green-600'
  if (enableColorScale && progressPercent !== 100)
    circleColour = 'text-yellow-600'

  return (
    <svg className={cn('flex-shrink-0', sizeClass)} viewBox="0 0 100 100">
      <circle
        className="text-gray-100"
        stroke="currentColor"
        strokeWidth="8"
        fill="transparent"
        cx="50"
        cy="50"
        r="30"
      />
      <circle
        className={circleColour}
        stroke="currentColor"
        strokeWidth="8"
        fill="transparent"
        cx="50"
        cy="50"
        r="30"
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
      />
    </svg>
  )
}
